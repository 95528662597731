<template>
  <div>
    <promotion-upload></promotion-upload>
  </div>
</template>

<script>
import PromotionUpload from "./../../components/promotions/upload";
export default {
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$t("promotions.upload.title")
    };
  },
  components: {
    PromotionUpload
  }
};
</script>